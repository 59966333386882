<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table
          class="ma-3 text-xs-center data_table"
          :loading="loading"
          :search="appBarSearch"
          :headers="headers"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          :items="searchResults"
          fixed-header
          dense
          hide-action
          :height="pageHeight(150)"
          group-by="[id]"
          ref="table"
          :footer-props="{
            showCurrentPage: false,
            showFirstLastPage: false,
            itemsPerPageOptions: [searchResults.length],
            itemsPerPageText: null
          }"
      >
        <template v-slot:[`group.header`]="{ group, headers, items, toggle,isOpen }" >
          <td @click="toggle" class="pa-2 text-left medical-teams-data-table" :colspan="headers.length" >
            <v-row dense class="my-n2">
              <v-col class="d-flex align-center justify-start pr-0 py-0" xl="3" lg="3" md="4" sm="5">
                <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                  <v-icon v-if="isOpen" >mdi-chevron-down</v-icon>
                  <v-icon v-else >mdi-chevron-right</v-icon>
                </v-btn>
                <span class="font-weight-bold">{{items[0].patientFullname}} {{ $t("medicalTeam") }}</span>
              </v-col>
              <v-col cols="2" lg="3" md="2" class="mx-0 my-0 py-0 px-0">
                <v-chip pill small color="primary" class="ma-2 text--black custom-chip-size" label>{{ items[0].membersList.length }} {{ items[0].membersList.length > 1 ? $t('members') : $t('member')}}</v-chip>
              </v-col>
              <v-col class="d-flex justify-start align-center px-0 py-0" xl="2" lg="4" md="3" sm="3">
                <!-- FIXME INSTEAD OF IMG WE WILL NEED THUMBNAIL -->
                <img height="24px" :src="returnImage(items[0].patientId)" />
                <span class="color-black d-flex align-center mx-2 font-weight-bold"> {{items[0].patientFullname}} </span>
              </v-col>
              <v-col cols="1">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon @click="navigateToPatientDetails(items[0].patientId)" color="main_black" v-on="on" style="margin-top:5px;"> mdi-open-in-new</v-icon>
                  </template>
                  <span>{{ $t('visit-patient') }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex align-center justify-end px-0 mr-4 py-0">
                <v-menu
                    left
                    bottom
                    offset-y
                    transition="slide-x-transition"
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                        icon
                        color="black"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <span class="mdi mdi-24px mdi-dots-horizontal mt-1"></span>
                    </v-btn>
                  </template >
                  <v-list>
                    <!-- <v-list-item link dense @click="synchronizeDrawers(items[0].patientId)"> -->
                    <v-list-item link dense @click="synchronizeDrawers(items[0])">
                      <v-icon color="main_black">mdi-pencil</v-icon>
                      <v-list-item-content class="custom-sm">{{$t("edit")}}</v-list-item-content>
                    </v-list-item>
                    <!--                  FUNCTIONAL DELETE IN CASE NEEDED-->
                    <!--                  <v-list-item-->
                    <!--                      v-for="(item, index) in items"-->
                    <!--                      :key="index"-->
                    <!--                      link-->
                    <!--                      dense-->
                    <!--                      @click.stop="deleteMedicalTeam(item.patientId, item.hospitalId)"-->
                    <!--                  >-->
                    <!--                    <v-list-item-icon class="ma-1 pa-1">-->
                    <!--                      <v-icon class="icon-img mdi-18px" color="red">mdi-delete</v-icon>-->
                    <!--                    </v-list-item-icon>-->
                    <!--                    <v-list-item-content class="d-flex align-left pl-0 pt-1 custom-sm color-delete">{{$t("delete")}}</v-list-item-content>-->
                    <!--                  </v-list-item>-->
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item="{ item }">
          <tr v-for="(member) in item.membersList" :key="member.patientId">
            <td class="py-2 text-left" style="border-bottom: none;">
              <v-row dense>
                <v-col cols="auto">
                  <img v-if="member.roleId === 4" :src="nurse_icon(member.status)" color="main_black" height="20px"/>
                  <img v-else-if="member.roleId === 3" :src="doctor_icon(member.status)" color="main_black" height="20px"/>
                </v-col>
                <v-col>
                  <span class="font-weight-medium text-left color-black" >{{ member.firstName }} {{member.lastName}}</span>
                </v-col>
              </v-row>
            </td>
            <td class="text-left font-weight-medium" style="border-bottom: none;">{{ member.roleId === 3 ? $t('doctor') : $t('nurse') }}</td>
            <td class="text-left color-black" style="border-bottom: none">
              <v-icon small color="main_black" class="mr-1">mdi-email</v-icon>{{ member.email }}
            </td>
            <td class="text-left" style="border-bottom: none; color:black;">
              <v-icon small color="main_black" class="mr-1">mdi-phone</v-icon>{{ member.phone }}
            </td>
            <td class="text-left" style="border-bottom: none;">{{ item.hospitalName }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { doctorBlack, doctorSecondary, nurseBlack, nurseSecondary, patientBlack } from '@/assets';
export default {
	data () {
		return {
			loading: true,
			hospitalName: '',
			doctor_black: doctorBlack,
			doctor_secondary: doctorSecondary,
			nurse_black: nurseBlack,
			hospitalId: null,
			nurse_secondary: nurseSecondary,
			patient_black: patientBlack,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			medicalTeamsRelated: (state) => state.medicalteam.medicalTeamsRelated,
		}),
		...mapGetters({
			getUser: 'authentication/getUser',
			getUserByIdImage: 'users/getUserByIdImage',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
			appBarMedicalSearch: 'filterbar/getMedicalTeam',
			roleName: 'authentication/getRole',
		}),
		headers () {
			const headers = [
				{ text: this.$t('name'), sortable: false, value: 'fullName', class: 'color_header pl-9' },
				{ text: this.$t('header-role'), sortable: false, value: 'role', class: 'color_header' },
				{ text: this.$t('header-email'), sortable: false, value: 'email', class: 'color_header' },
				{ text: this.$t('header-phone'), sortable: false, value: 'phone', class: 'color_header' },
				{ text: this.$t('header-hospital-name'), sortable: false, value: 'hospitalName', class: 'color_header' },
			];
			return headers;
		},
		searchResults () {
			const searchTerm = this.appBarMedicalSearch ? this.appBarMedicalSearch.toLowerCase() : '';
			if (this.medicalTeamsRelated != null) {
				return this.medicalTeamsRelated.filter(item => {
					return item?.patientFullname?.toLowerCase().includes(searchTerm);
				});
			} else {
				return [];
			}
		},
		profileImage () {
			return this.getUserByIdImage;
		},
	},
	async mounted () {
		await this.showMedicalTeam();
		// load the list of related hospital users only once, and then use it from the store
		await this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userData.hospitalAssignedData.id);
	},
	methods: {
		returnImage (patientId) {
			// TODO: RECHECK THIS LOGIC HERE
			if (this.profileImage !== null && this.profileImage[patientId]) {
				return 'data:image/jpeg;base64,' + this.profileImage[patientId];
			} else {
				return this.patient_black;
			}
		},
		navigateToPatientDetails (patientId) {
			this.$router.push({ name: 'Patient Details', params: { id: patientId } });
		},
		async synchronizeDrawers (item) {
			// await this.$store.commit('medicalteam/SELECTED_MEDICAL_TEAM', item); // use the store, not from API call
			await this.$store.dispatch('medicalteam/getMedicalTeamForPatient', item.patientId);
      // await this.$store.dispatch('medicalteam/getMedicalTeamForPatient', item);

			// we are nopt calling this anymore, we call it once onle on mounted()
			// await this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userData.hospitalAssignedData.id);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', { item, updateDrawer: true, page2: 'medicalteam' });
			this.loading = false;
		},
		async showMedicalTeam () {
			this.toggleHeaders();
			this.loading = true;

			if (this.roleName === 'HOSPITAL_ADMIN') {
				await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			} else if (this.roleName === 'DOCTOR') {
				const data = { nurseId: this.getUser.id, hospitalId: this.getUser.hospitalAssignedData.id };
				await this.$store.dispatch('medicalteam/getMedicalTeamForDoctor', data);
			} else if (this.roleName === 'NURSE') {
				const data = { nurseId: this.getUser.id, hospitalId: this.getUser.hospitalAssignedData.id };
				await this.$store.dispatch('medicalteam/getMedicalTeamForNurse', data);
			} else {
				await this.$store.dispatch('medicalteam/getMedicalTeamForHospitalAdmin', this.getUser.id);
			}

			this.loading = false;
			this.toggleHeaders();
		},

		// FUNCTIONAL DELETE IN CASE NEEDED BEFORE ENABLE CHECK MEDICALTEAM.JS TO ENABLE THE ACTION FOR DELETE
		// async deleteMedicalTeam (patientId, hospitalId) {
		// 	const medicalTeamBody = {
		// 		patientId: patientId,
		// 		hospitalId: hospitalId,
		// 	};
		//
		// 	await this.$store.dispatch('medicalteam/deleteMedicalTeam', medicalTeamBody);
		// 	await this.showMedicalTeam();
		// },

		doctor_icon (item) {
			switch (item) {
			case 1:
				return this.doctor_black;
			default:
				return this.doctor_secondary;
			}
		},
		nurse_icon (item) {
			switch (item) {
			case 1:
				return this.nurse_black;
			default:
				return this.nurse_secondary;
			}
		},
		toggleHeaders () {
			const table = this.$refs.table;
			if (table) {
				const keys = Object.keys(table?.$vnode.componentInstance.openCache);
				keys.forEach(x => {
					table.$vnode.componentInstance.openCache[x] = false;
				});
			}
		},
	},
};
</script>

<style>
.v-data-footer{
  justify-content: right;
  margin-right: 0px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
  .data_table{
    cursor: pointer;
    border: #ECEAF3 solid 1px;
    border-radius: 1px;
  }
  .color_header {
  background: #ECEAF3 !important;
  padding-left: 15px;
  }
  .icon-img {
  width: 23px;
  height: 23px;
  }
  .custom-sm{
  font-size: 14px;
  font-weight: bold;
  }
  .color-delete{
  color: red;
  }
  </style>
